h1 {
    font-size: 3rem;
    font-weight: 700;
}
h2 {
    font-size: 2.5rem;
    font-weight: 500;
}
h3 {
    font-size: 2rem;
    font-weight: 500;
}
h4 {
    font-size: 1.6rem;
    font-weight: 700;
}
h5 {
    font-size: 1.6rem;
    font-weight: 300;
}
p {
    font-size: 1.6rem;
    font-weight: 400;
}