.App {
  width: 100%;
  height: 100vh;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @include respond(phone) {
    background-position: right -245px center;
  }
}
