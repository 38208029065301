.weather {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem 2rem;
  background-color: $color-primary-dark;
  font-size: 1rem;
  color: $color-grey-light;

  &__icon {
    width: 2rem;
    margin: 0 0.5rem;
  }
}
